import React, { FC } from 'react'
import styled, { StyledProps } from 'styled-components'
import { Box } from '@ucheba/ui/components/Box/touch'
import { List, ListItem } from '@ucheba/ui/components/List/touch'
import { Text } from '@ucheba/ui/components/Text/touch'
import { Formik, Field } from 'formik'
import { TextField } from '@ucheba/ui/components/TextField/touch'
import { Spacing } from '@ucheba/ui/components/Spacing/touch'
import { Grid, GridCol } from '@ucheba/ui/components/Grid/touch'
import { Button } from '@ucheba/ui/components/Button/touch'
import { ErrorText } from '@ucheba/ui/components/ErrorText/touch'
import { EDataKeys } from '@ucheba/utils/types/dataKeys'
import { Form } from '@ucheba/ui/components/Form/desktop'
import { EButtonColorByTheme, EInputsColor, ILeadFormProps } from '../types'
import ImgLarge from '../assets/img__main_touch.png'
import MessengersDialog from './MessengersDialog'
import { useLeadForm } from '../bll/useLeadForm'
import PhoneCodeDialog from './PhoneCodeDialog'

const Block = styled(Box)`
  border-radius: 8px;
  padding: 0;
`

const BoxSC = styled(Box)``

const Img = styled.img`
  width: 16px;
`

const ImgMain = styled.img`
  width: 100%;
`

const TextST = styled(Text)`
  letter-spacing: 1.4px;
`

const GridColSC = styled<StyledProps<any>>(GridCol)`
  display: ${(props) => (props.showInputs ? 'block' : 'none')};
`

const LeadForm: FC<ILeadFormProps> = (props) => {
  const {
    title,
    list,
    text,
    onSubmitForm,
    onCheckedCode,
    withMessengers = true,
    color = 'plum',
    buttonText,
    imgUrl,
    overTitle,
    children,
    titleSize = 'h2',
    institutionId,
    activityType,
    leadNote,
    infoPart,
  } = props

  const {
    initialValues,
    onSubmit,
    getImg,
    onPhoneConfirmSubmit,
    onPhoneConfirmClose,
    needConfirmPhone,
    needShowMessengers,
    onMessengersDialogClose,
    phone,
    isLoading,
    error,
    showInputs,
  } = useLeadForm({
    onSubmitForm,
    onCheckedCode,
    leadNote,
    infoPart,
    institutionId,
    activityType,
  })

  return (
    <Block color={color as any}>
      <BoxSC color={color as any} spacing='small'>
        {children}

        {text || title || list ? (
          <>
            {overTitle && (
              <>
                <Spacing spacing='usmall' />

                <TextST uppercase>{overTitle}</TextST>

                <Spacing spacing='usmall' />
              </>
            )}

            {title && (
              <Text
                fontSize={titleSize}
                lineHeight={titleSize as any}
                fontWeight='extrabold'
              >
                {title}
              </Text>
            )}

            {text && (
              <>
                <Spacing spacing='xxsmall' />

                <Text>{text}</Text>
              </>
            )}

            <Spacing spacing='small' />

            {list && (
              <List spacing='xxsmall'>
                {list.map((txt, key) => {
                  return (
                    <ListItem
                      alignItems='flex-start'
                      key={key}
                      StartIcon={getImg(key) ? <Img src={getImg(key)} /> : undefined}
                    >
                      <Text fontSize='h4' lineHeight='h4' fontWeight='bold' color='white'>
                        {txt}
                      </Text>
                    </ListItem>
                  )
                })}
              </List>
            )}

            <Spacing spacing='small' />
          </>
        ) : null}

        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          {(): JSX.Element => (
            <Form fillByProfile>
              <Grid spacing='small'>
                <GridColSC showInputs={showInputs}>
                  <Field
                    as={TextField}
                    name={EDataKeys.name}
                    label='Имя'
                    color={EInputsColor[color]}
                    required
                  />
                </GridColSC>

                <GridColSC showInputs={showInputs}>
                  <Field
                    as={TextField}
                    name={EDataKeys.phone}
                    label='Телефон'
                    color={EInputsColor[color]}
                    required
                    type='tel'
                  />
                </GridColSC>

                <GridCol>
                  <Button
                    color={EButtonColorByTheme[color] || 'gold'}
                    block
                    type='submit'
                    progress={isLoading}
                  >
                    {buttonText || 'Отправить'}
                  </Button>

                  {error && (
                    <>
                      <Spacing spacing='xsmall' />

                      <ErrorText>
                        Проверьте правильность ввода данных и повторите попытку.
                      </ErrorText>
                    </>
                  )}
                </GridCol>
              </Grid>
            </Form>
          )}
        </Formik>
      </BoxSC>

      <ImgMain src={imgUrl || ImgLarge} />

      {phone && needConfirmPhone ? (
        <PhoneCodeDialog
          open={needConfirmPhone}
          phone={phone}
          onSubmit={onPhoneConfirmSubmit}
          onClose={onPhoneConfirmClose}
          isLoading={isLoading}
        />
      ) : null}

      <MessengersDialog
        open={needShowMessengers && withMessengers}
        onClose={onMessengersDialogClose}
      />
    </Block>
  )
}

export default LeadForm
