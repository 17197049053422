export enum EProductTypes {
  ege = 'ege',
  admissionSupport = 'admission_support',
  oge = 'oge',
  proforientation = 'proforientation',
  proforientationRequest = 'proforientation_request',
  ege_test = 'ege_test',
  select_institution = 'select_institution',
  classroom_hour = 'classroom_hour',
}
