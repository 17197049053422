import { advSlice } from './index'

export interface IAdvState {
  [key: string]: ReturnType<typeof advSlice.reducer>
}

export interface IAdvBlock {
  heading: string
  image_url: string
  text: string
  url: string
  url_text: string
  text_image_url: string
  color_theme: 'gold' | 'navy' | 'blue' | 'lime' | 'rose' | 'plum'
  template: EBannerTemplate
  overTitle?: string | null
  clientName?: string | null
  url_video?: string
  lead_institution_id?: string | number | null
  lead_note?: string | null
  is_live?: boolean
}

export enum EBannerTemplate {
  vertical = 'vertical',
  horizontal = 'horizontal',
  contentHorizontal = 'contentHorizontal',
  notice = 'notice',
  bottomSheet = 'bottomSheet',
  bottomSticky = 'bottomSticky',
  bottom = 'bottom',
  horizontalForm = 'horizontalForm',
  verticalForm = 'verticalForm',
  noticeForm = 'noticeForm',
  bottomSheetForm = 'bottomSheetForm',
  contentHorizontalForm = 'contentHorizontalForm',
}
