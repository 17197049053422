import React, { FC, memo } from 'react'
import styled from 'styled-components'
import { BottomSheet } from '@ucheba/ui/components/BottomSheet/touch'
import { IMessengerDialogProps } from '../types'
import ApplicationFormMessengers from '../../AppliactionForm/touch/ApplicationFormMessengers'
import SC from '../../AppliactionForm/base/ApplicationForm.styles'

const { Block: BlockSC } = SC
const Block = styled(BlockSC)``
const MessengersDialog: FC<IMessengerDialogProps> = (props) => {
  const { open, onClose } = props

  return (
    <BottomSheet open={open} toggleOpen={onClose}>
      <Block>
        <ApplicationFormMessengers />
      </Block>
    </BottomSheet>
  )
}

export default memo(MessengersDialog)
