import { useDispatch, useSelector } from 'react-redux'
import {
  authPhoneCodeActions,
  authPhoneCodeSelectors,
  authPhoneCodeThunks,
} from '@ucheba/store/auth/phone/code'
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { useTimer } from '@ucheba/utils/hooks/useTimer'
import { codeLength } from '../constants'

interface IProps {
  phone: string
  onSubmit: (
    code: string,
    phone: string,
    setError?: Dispatch<SetStateAction<string | null>>
  ) => void
}

interface IUseCodeDialog {
  (props: IProps): {
    timeLeft: string
    isTimeLeft: boolean
    onInputCode: (_: any, codeValue: any) => void
    getNewCode: () => void
    error: string | null
  }
}

export const useCodeDialog: IUseCodeDialog = (props) => {
  const { phone, onSubmit } = props
  const dispatch = useDispatch()
  const timout = useSelector(authPhoneCodeSelectors.timeout)
  const [error, setError] = useState<string | null>(null)
  /* Получаем таймер */
  const { timeLeft, isTimeLeft } = useTimer({
    seconds: timout,
    secondsByMinutes: true,
  })

  const onInputCode = useCallback(
    (_, codeValue) => {
      if (codeValue.length === codeLength) {
        onSubmit(codeValue, phone, setError)
      }
      setError(null)
    },
    [onSubmit, phone]
  )
  /* Когда заканчивается время, ресетим его в сторе */
  useEffect(() => {
    if (isTimeLeft) {
      dispatch(authPhoneCodeActions.setTimeoutValue(0))
    }
  }, [dispatch, isTimeLeft])

  /* Метод запроса нового кода */
  const getNewCode = useCallback(async () => {
    await dispatch(authPhoneCodeThunks.sendCode({ data: { phone } }))
  }, [dispatch, phone])

  return {
    timeLeft,
    isTimeLeft,
    onInputCode,
    getNewCode,
    error,
  }
}
