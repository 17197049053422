import React, { FC, memo, useRef } from 'react'
import styled from 'styled-components'
import { Box } from '@ucheba/ui/components/Box/touch'
import { Text } from '@ucheba/ui/components/Text/touch'
import { Tooltip } from '@ucheba/ui/components/Tooltip/touch'
import { Notice } from '@ucheba/ui/components/Notice/touch'
import { Heading } from '@ucheba/ui/components/Heading/touch'
import { EActivityInfoPart, EActivityTypes } from '@ucheba/utils/types/userActivityTypes'
import { useNotice } from '@ucheba/ui/components/Notice/bll'
import { useSelector } from 'react-redux'
import { authSelectors } from '@ucheba/store/auth'
import { EFunnels } from '@ucheba/utils/types/funnels'
import { ETypeRequest } from '@ucheba/utils/types/typeRequest'
import { EProductTypes } from '@ucheba/utils/types/productTypes'
import LeadForm from '../../LeadForm/touch'
import { IBannerLidForm } from '../types'
import LidMagnetForm from '../../LidMagnetForm/touch'

const Wrapper = styled.div`
  overflow: hidden;
`

const AdvText = styled.div`
  z-index: 999;
  height: 100%;
  cursor: default;
  display: flex;
  justify-content: center;
  padding: 8px 0;
`

const TextAdv = styled(Text)`
  letter-spacing: 1px;
`

/** Компонент рекламный баннерр c лид формой */
const BannerLidFormVertical: FC<IBannerLidForm> = (props) => {
  const { content, pixelImg, id, bannerId } = props

  const {
    text,
    heading,
    color_theme,
    clientName,
    overTitle,
    url_text,
    image_url,
    lead_institution_id,
    lead_note,
  } = content

  const isAuth = useSelector(authSelectors.isAuth)

  const refDate = useRef<number>(Date.now())
  const notices = useNotice(
    `leadFormBannerId${lead_institution_id}${id}${bannerId}${refDate.current}`
  )

  return (
    <>
      <Wrapper as={Box} color={color_theme as any} spacing='null'>
        <div
          style={{
            width: '100%',
          }}
        >
          {lead_institution_id && (
            <LeadForm
              institutionId={Number(lead_institution_id)}
              activityType={EActivityTypes.lead_form_request}
              leadNote={lead_note}
              infoPart={EActivityInfoPart.lead_form}
              size='small'
              title={heading}
              text={text}
              onSubmitForm={() => {
                notices.addNotice()
              }}
              withMessengers={false}
              overTitle={overTitle}
              buttonText={url_text}
              imgUrl={image_url}
              color={color_theme as any}
            />
          )}

          {!lead_institution_id && (
            <LidMagnetForm
              funnel={EFunnels.ucheba_search_lead_form}
              requestTarget={ETypeRequest.ege_lead_request}
              productType={EProductTypes.ege}
              showInputs={!isAuth}
              size='small'
              title={heading}
              text={text}
              onSubmitForm={() => {
                if (isAuth) {
                  notices.addNotice()
                }
              }}
              onCheckedCode={() => {
                notices.addNotice()
              }}
              withMessengers={false}
              overTitle={overTitle}
              buttonText={url_text}
              imgUrl={image_url}
              color={color_theme as any}
            />
          )}

          {pixelImg && (
            <img src={pixelImg} style={{ width: '1px', height: '1px' }} alt='' />
          )}
        </div>
      </Wrapper>

      {clientName && (
        <AdvText>
          <Tooltip
            content={<Text color='black'>{clientName}</Text>}
            position='top-center'
            width='300px'
          >
            <TextAdv
              textAlign='center'
              fontSize='xsmall1'
              lineHeight='xsmall1'
              color='gray25'
            >
              РЕКЛАМА
            </TextAdv>
          </Tooltip>
        </AdvText>
      )}

      <Notice
        color='white'
        showClose={false}
        onClose={notices.removeNotice}
        open={notices.open}
        preventAutoClose={false}
      >
        <Heading h3='Заявка успешно отправлена' spacing='null' />
      </Notice>
    </>
  )
}

export { BannerLidFormVertical }
export default memo(BannerLidFormVertical)
