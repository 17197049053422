import React, { FC, memo } from 'react'
import styled from 'styled-components'
import { Heading } from '@ucheba/ui/components/Heading/touch'
import { Text } from '@ucheba/ui/components/Text/touch'
import { Spacing } from '@ucheba/ui/components/Spacing/touch'
import { Grid, GridCol } from '@ucheba/ui/components/Grid/touch'
import { CodeTextField } from '@ucheba/ui/components/CodeTextField/touch'
import { Box } from '@ucheba/ui/components/Box/touch'
import { BottomSheet } from '@ucheba/ui/components/BottomSheet/touch'
import { Button } from '@ucheba/ui/components/Button/touch'
import { ErrorText } from '@ucheba/ui/components/ErrorText/touch'
import content from '../../AppliactionForm/data.json'
import SC from '../../AppliactionForm/base/ApplicationForm.styles'
import { IPhoneConfirmDialogProps } from '../types'
import { useCodeDialog } from '../bll/useCodeDialog'

const { Block: BlockSC } = SC
const Block = styled(BlockSC)`
  overflow: hidden;
`

const ButtonSC = styled(Button)`
  padding: 0;
  height: auto;
`

const PhoneCodeDialog: FC<IPhoneConfirmDialogProps> = (props) => {
  const { open, onClose, onSubmit, isLoading, phone } = props

  const { timeLeft, isTimeLeft, onInputCode, getNewCode, error } = useCodeDialog({
    phone,
    onSubmit,
  })

  return (
    <BottomSheet open={open} toggleOpen={onClose}>
      <Block>
        <Box color='white' spacing='small'>
          <Heading h3={content.code.title} spacing='small' textAlign='center' />

          <Text textAlign='center'>{`${content.code.phone.prevText} ${phone}`}</Text>

          <Spacing spacing='xlarge' />

          <Grid justifyContent='center'>
            <GridCol width='auto'>
              <CodeTextField onChange={onInputCode} disabled={isLoading} />
            </GridCol>
          </Grid>

          {error && (
            <>
              <Spacing spacing='xsmall' />

              <ErrorText textAlign='center'>{error}</ErrorText>
            </>
          )}

          <Spacing spacing='xlarge' />

          {isTimeLeft ? (
            <ButtonSC view='link' onClick={getNewCode} block size='small'>
              {content.code.getNewCodeLinkText}
            </ButtonSC>
          ) : (
            <Text textAlign='center' fontSize='small1' color='gray60'>
              {`${content.code.prevTextNewCode} ${timeLeft}`}
            </Text>
          )}
        </Box>
      </Block>
    </BottomSheet>
  )
}

export default memo(PhoneCodeDialog)
