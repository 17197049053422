import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { authPhoneThunks } from '@ucheba/store/auth/phone'
import { authPhoneCodeThunks } from '@ucheba/store/auth/phone/code'
import { profileRegistrationThunks } from '@ucheba/store/profile/registration'
import { authSelectors } from '@ucheba/store/auth'
import Img3 from '../assets/img__3.png'
import Img2 from '../assets/img__2.png'
import Img1 from '../assets/img__1.png'
import { useUserActivity } from '../../../../utils/hooks/useUserActivity'
import { IUseLeadForm } from '../types'

export const useLeadForm: IUseLeadForm = (props) => {
  const { onCheckedCode, onSubmitForm, leadNote, institutionId, activityType, infoPart } =
    props
  const dispatch = useDispatch()
  const isAuth = useSelector(authSelectors.isAuth)
  const [needConfirmPhone, setNeedConfirmPhone] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [needShowMessengers, setNeedShowMessengers] = useState(false)
  const [error, setError] = useState(false)
  const [currentFormValues, setCurrentFormValues] = useState<{
    name: string
    phone: string
  } | null>(null)
  const getImg = useCallback((key: number) => {
    switch (key) {
      case 0:
        return Img1
      case 1:
        return Img2
      case 2:
        return Img3
      default:
        return null
    }
  }, [])
  const { setUserActivity } = useUserActivity()

  const handleActivity = useCallback(async () => {
    await setUserActivity({
      activityType,
      institutionId,
      data: { infoPart, leadNote },
    })

    if (onSubmitForm) {
      onSubmitForm()
    }
  }, [activityType, infoPart, institutionId, leadNote, onSubmitForm, setUserActivity])

  const initialValues = useMemo(() => {
    return {
      name: '',
      phone: '',
    }
  }, [])

  const sendCode = useCallback(
    async (values: any): Promise<any> => {
      const data = {
        phone: values.phone,
      }

      const result = await dispatch(authPhoneCodeThunks.sendCode({ data }))

      return result
    },
    [dispatch]
  )

  const onSubmit = useCallback(
    async (values) => {
      setError(false)
      setCurrentFormValues(values)
      setIsLoading(true)

      if (isAuth) {
        handleActivity()
      } else {
        const response = await sendCode(values)

        if (response?.error) {
          setError(true)
        } else {
          // Если нужно подтвердить телефон
          setNeedConfirmPhone(true)
        }
      }

      setIsLoading(false)
    },
    [handleActivity, isAuth, sendCode]
  )

  const onMessengersDialogClose = useCallback(() => {
    setNeedShowMessengers(false)
  }, [setNeedShowMessengers])

  const onPhoneConfirmSubmit = useCallback(
    async (
      code: string,
      phone: string,
      setErrorCode?: Dispatch<SetStateAction<string | null>>
    ) => {
      setIsLoading(true)
      if (setErrorCode) {
        setErrorCode(null)
      }

      let res = (await dispatch(authPhoneThunks.phone({ data: { code, phone } }))) as any

      if (!res.error && !res?.payload) {
        res = await dispatch<any>(
          profileRegistrationThunks.fetch({
            data: {
              phone,
              name: currentFormValues?.name,
              phoneCode: code,
            },
          })
        )
      }

      if (!res.error && res?.payload) {
        if (onCheckedCode) {
          onCheckedCode()
        }

        handleActivity()
        setNeedShowMessengers(true)
        setNeedConfirmPhone(false)
      }

      if (res.error && setErrorCode) {
        setErrorCode('Проверьте правильность ввода данных и повторите попытку.')
      }

      setIsLoading(false)
    },
    [dispatch, currentFormValues?.name, onCheckedCode, handleActivity]
  )

  const onPhoneConfirmClose = useCallback(() => {
    setNeedConfirmPhone(false)
    setIsLoading(false)
  }, [setNeedConfirmPhone])

  const showInputs = useMemo(() => !isAuth, [isAuth])

  return {
    getImg,
    initialValues,
    onSubmit,
    onPhoneConfirmSubmit,
    onPhoneConfirmClose,
    needConfirmPhone,
    needShowMessengers,
    onMessengersDialogClose,
    phone: currentFormValues?.phone || null,
    isLoading,
    error,
    showInputs,
  }
}
