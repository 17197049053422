import styled, { keyframes } from 'styled-components'
import { sv } from '@ucheba/utils/helpers/styles/variables'

const animation = keyframes`
  0% {
    opacity: .5;
    height: 6px;
    width: 6px;
    transition: opacity .5s linear;
  }
  50% {
    opacity: 0;
    transform: scale(4);
    transition: opacity .5s linear;
  }
  100% {
    opacity: 0;
    transition: opacity .5s ease;
  }
`

const DotContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-right: 10px;
`

const Dot = styled.div`
  background-color: ${sv.color.red};
  height: 6px;
  width: 6px;
  border-radius: 50%;
`

const DotPulse = styled.div`
  border-radius: 50%;
  margin: 0;
  background-color: ${sv.color.red};
  animation: ${animation} 2s infinite;
`

export default { DotContainer, Dot, DotPulse }
